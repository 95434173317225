import React from 'react'

export const Contact = () => {
  return (
  <>
     <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh',fontWeight:'bold'}}>
      <div><h1>Contact Us</h1></div>
    </div>
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh',fontWeight:'bold'}}>
        <h6>For any question, Contact Us through below:</h6>
    </div>
    <ul>
   <li>Please contact us on the email address: rummyjio@gmail.com</li>
    </ul>
  </>
  )
}
