import React from 'react'
import btnDownload from '../components/Image/downloadbtn.png'
import logoImg from '../components/Image/logoNew.webp'
import banner2 from '../components/Image/casinobg2.webp'
import btn2 from '../components/Image/downloadbtn2.png'
import line from '../components/Image/line.png'
import slide1 from '../components/Image/slide1.jpg'
import slide2 from '../components/Image/lobiNew4.png'
import slide3 from '../components/Image/slide4.jpg'
import borderImg from '../components/Image/border.png'
import bgForGames from '../components/Image/bgforGames.png'
import '../components/Home.css'
import gameHead from '../components/Image/gamesHeading.png'
import image1 from '../components/Image/3 patti-min.webp'
import image2 from '../components/Image/image2.webp'
import image3 from '../components/Image/image3.webp'
import image4 from '../components/Image/image4.webp'
import image5 from '../components/Image/image5.webp'
import image6 from '../components/Image/image6.webp'
import image7 from '../components/Image/image7.webp'
import image8 from '../components/Image/image8.webp'
import profile1 from '../components/Image/profile1.webp'
import profile2 from '../components/Image/profile2.webp'
import { Downloadbtn } from './donloadbtn/downloadbtn'
import { Link } from 'react-router-dom'
import video1 from '../components/Image/video3.mp4' 


export const Home = () => {
  const handleDownload = () => {
    // Replace 'yourfile.apk' with the name of your APK file
    const apkUrl = process.env.PUBLIC_URL + '/Jio Rummy Live.apk';
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'Jio Rummy Live.apk'; // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <>
      <div className='bgStyles'>
        <div className='bgStyles2'>
          <div style={{ position: 'fixed', bottom: '10px', zIndex: '999' }}>
            <div>
              <Downloadbtn/>
            </div>

          </div>

          <div style={{ height: '10vh', width: '100%', background: 'linear-gradient(82deg, #bb1717 0, #bb1717 0, #cd8200 99%, #cd8200 99%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
            <div style={{ height: '7vh', width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ width: '30%', height: '7vh' }}>
                <img src={logoImg} alt='' style={{ width: '100%', height: '7vh' }} />
              </div>
              <div style={{ width: '65%', height: '3vh', lineHeight: '2px' }}>
                <p style={{ color: 'gold' }}>Jio Rummy Live</p>
                <p style={{ fontSize: '10px', color: 'white' }}>No.1 Gaming Platform</p>
              </div>
            </div>
            <div style={{ height: '8vh', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button style={{ background: 'none', border: 'none', padding: '0px' }} onClick={handleDownload} download><img src={btnDownload} alt='' style={{ width: '100%', height: 'auto' }} /></button>
            </div>
          </div>

          <div style={{ height: '280vh', width: '100%', background: '#792710' }}>
            <div className='imgPostion'>
              <img src={banner2} alt='' style={{ width: '100%', height: '33vh' }} />
              <div style={{position:'absolute',top:'3%',left:'30%'}}>
                    <img src={logoImg} alt='' style={{width:'100%',height:'22vh'}}/>
              </div>
              <div className='imgPostion2'>
              <video controls width="100%"   style={{ width: '100%', height: 'auto', borderRadius: '10px',border:'3px solid goldenrod' }}  loop autoPlay muted>
      <source src={video1} type="video/mp4"/>
      Sorry, your browser doesn't support embedded videos.
    </video>
              </div>
            </div>
            <div style={{ height: '25vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div className="text-center" style={{ height: '15vh', textAlign: 'center', padding: '0px 20px' }}>
                <p style={{ textShadow: '0 0 0.2667rem #ffd000', fontWeight: '500', color: 'white' }}>"Discover the best casino games in India with a user-friendly interface for playing, winning, and withdrawing. Earn thousands of rupees daily through our refer and earn program!"

           </p>
              </div>
              <div style={{ height: '10vh', width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button style={{ border: 'none', background: 'none' }} onClick={handleDownload} download><img src={btn2} alt='' style={{ width: '50%', height: 'auto' }} /></button>
              </div>
            </div>
            <div style={{ height: '6vh' }}>
              <img src={line} alt='' style={{ width: '100%', height: 'auto' }} />
            </div>
            <div style={{ height: '34vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel" style={{ width: '80%' }}>
                <div class="carousel-inner">
                  <div class="carousel-item active" data-bs-interval="10000">
                    <img src={slide1} class="d-block w-100" alt="..." style={{ height: '29vh' }} />
                  </div>
                  <div class="carousel-item" data-bs-interval="2000">
                    <img src={slide2} class="d-block w-100" alt="..." style={{ height: '29vh' }} />
                  </div>
                  <div class="carousel-item">
                    <img src={slide3} class="d-block w-100" alt="..." style={{ height: '29vh' }} />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <div className='borderCss'>
                <img src={borderImg} alt='' />
              </div>
            </div>
            <div style={{ heigh: '50vh', position: 'relative' }}>
              <img src={bgForGames} alt='' style={{ width: '100%', height: 'auto' }} />
              <div class="cardContainer" style={{ position: 'absolute', top: '19%', left: '0%', height: '30vh', width: '100%' }}>
                <img src={gameHead} alt="" style={{ width: '100%', height: '10vh' }} />
                <ul class="cards" style={{ height: '20vh', padding: '27px 10px' }}>
                  <li class="">
                    <div class="card-images">
                      <img src={image1} alt="Image 1" />
                      <img src={image2} alt="Image 2" />
                      <img src={image3} alt="Image 3" />
                      <img src={image4} alt="Image 4" />
                      <img src={image5} alt="Image 5" />
                      <img src={image6} alt="Image 6" />
                      <img src={image7} alt="Image 7" />
                      <img src={image8} alt="Image 8" />
                    </div>

                  </li>

                </ul>
              </div>
            </div>
            <div style={{ height: '90vh', width: '100%' }}>
              <div style={{ height: '14vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='text-center p-3' style={{ height: '10vh', width: '90%', background: 'linear-gradient(to top, #8f5d00, #d3a510)', boxShadow: '0 0 0.2667rem 0 #331006', borderRadius: '10px', border: 'none' }}>
                  <p style={{ color: '#fff', fontSize: '14px', lineHeight: '18px', textShadow: '0 0 0.2667rem 0 #331006', fontWeight: '700' }}>We have many loyal players
                    <br></br> Real reviews from real players</p>
                </div>
              </div>
              <div style={{ height: '62vh' }}>
                <div style={{ borderBottom: '1px solid #331006', height: '19vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ height: '18vh', width: '22%' }}>
                    <img src={profile1} alt='' style={{ width: '90%', height: 'auto', border: '2px solid goldenrod', borderRadius: '50%' }} />
                  </div>
                  <div style={{ height: '20vh', width: '60%', color: 'white', lineHeight: '4px', display: 'flex', flexDirection: 'column', justifyContent: "center" }}>
                    <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Rikita Dahiya</p>
                    <p style={{ fontSize: '10px' }}>Vadodra,Gujarat</p>
                    <p style={{ fontSize: '12px', lineHeight: '15px' }}>
                      "Playing on Jio Rummy Live is an absolute joy! I recently won a tournament, and it made me incredibly happy. Thank you, Jio Rummy Live team, for such an incredible experience!"
                    </p>
                  </div>
                </div>
                <div style={{ borderBottom: '1px solid #331006', height: '22vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ height: '18vh', width: '22%' }}>
                    <img src={profile2} alt='' style={{ width: '90%', height: 'auto', border: '2px solid goldenrod', borderRadius: '50%' }} />
                  </div>
                  <div style={{ height: '18vh', width: '60%', color: 'white', lineHeight: '4px', display: 'flex', flexDirection: 'column', justifyContent: "center" }}>
                    <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Vikram Singh</p>
                    <p style={{ fontSize: '10px' }}>Pune,Maharsahtra</p>
                    <p style={{ fontSize: '12px', lineHeight: '14px' }}>
                        "I play on Jio Rummy Live every day, and I'm always excited for each game. The platform not only brings excitement but also provides a secure environment for playing online rummy."
                    </p>
                  </div>
                </div>
                <div style={{ height: '22vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ height: '18vh', width: '22%' }}>
                    <img src={profile2} alt='' style={{ width: '90%', height: 'auto', border: '2px solid goldenrod', borderRadius: '50%' }} />
                  </div>
                  <div style={{ height: '18vh', width: '60%', color: 'white', lineHeight: '4px', display: 'flex', flexDirection: 'column', justifyContent: "center" }}>
                    <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Yogesh Tiwari</p>
                    <p style={{ fontSize: '10px' }}>Siliguri,West Bengal</p>
                    <p style={{ fontSize: '12px', lineHeight: '14px' }}>
                    "Jio Rummy Live offers amazing features like live chat support, lightning-fast withdrawals, secure transactions, and outstanding customer service. It's a fantastic platform for rummy enthusiasts!"

                    </p>
                  </div>
                </div>
              </div>
              <div style={{ height: '14vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='text-center p-2' style={{ height: '8vh', width: '90%', background: 'linear-gradient(to top, #8f5d00, #d3a510)', boxShadow: '0 0 0.2667rem 0 #331006', borderRadius: '10px', border: 'none' }}>
                  <p style={{ color: '#fff', fontSize: '18px', textShadow: '0 0 0.2667rem 0 #331006', fontWeight: '700' }}>Join them now
                  </p>
                </div>
              </div>
            </div>
            <div style={{ background: '#3b0303', height: '52vh', width: '100%' }}>
              <div style={{ borderBottom: '1px solid #5e1b09', height: '20vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <div style={{ height: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={logoImg} alt='' style={{ width: '15%', height: '8vh' }} />
                  <button style={{ background: 'none', border: 'none', padding: '0px', width: '36%' }} onClick={handleDownload} download><img src={btnDownload} alt='' style={{ width: '72%', height: 'auto' }} /></button>
                </div>
                <div className='text-center' style={{ height: '5vh' }}>
                  <p style={{ color: 'white', fontSize: '12px' }}> This Game is Only meant for the 18+age group.</p>
                </div>
              </div>
              <div style={{ borderBottom: '1px solid #5e1b09', height: '13vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ height: '4vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <div style={{ width: '18%', height: '4vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <div style={{ height: '3vh', borderRight: '1px dashed #afafaf', padding: '0px 7px' }}>
                      <p style={{ color: 'white', fontSize: '12px' }}><Link to='/about' style={{ textDecoration: 'none', color: 'white' }}>About Us</Link></p>
                    </div>

                  </div>
                  <div style={{ width: '35%', height: '4vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <div style={{ height: '3vh', borderRight: '1px dashed #afafaf', padding: '0px 7px' }}>
                      <p style={{ color: 'white', fontSize: '12px' }}><Link to='/terms' style={{ textDecoration: 'none', color: 'white' }}>Terms & Conditions</Link></p>
                    </div>
                  </div>
                  <div style={{ width: '25%', height: '4vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <div style={{ height: '3vh', padding: '0px 7px' }}>
                      <p style={{ color: 'white', fontSize: '12px' }}><Link to='/privacy' style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</Link></p>
                    </div>

                  </div>
                </div>
                <div style={{ height: '4vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <div style={{ width: '45%', height: '4vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <div style={{ height: '3vh', borderRight: '1px dashed #afafaf', padding: '0px 7px' }}>
                      <p style={{ color: 'white', fontSize: '12px' }}><Link to='/cancelation' style={{ textDecoration: 'none', color: 'white' }}>Cancellation & Refund Policy</Link></p>
                    </div>

                  </div>
                  <div style={{ width: '18%', height: '4vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <div style={{ height: '3vh', borderRight: '1px dashed #afafaf', padding: '0px 7px' }}>
                      <p style={{ color: 'white', fontSize: '12px' }}><Link to='/pricing' style={{ textDecoration: 'none', color: 'white' }}>Pricing</Link></p>
                    </div>
                  </div>
                  <div style={{ width: '20%', height: '4vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <div style={{ height: '3vh', padding: '0px 7px' }}>
                      <p style={{ color: 'white', fontSize: '12px' }}><Link to='/contact' style={{ textDecoration: 'none', color: 'white' }}>Contact Us</Link></p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ height: '19vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className='text-center p-2' style={{ height: '12vh', color: 'white', fontSize: '13px' }}>
                  <p>"Please play responsibly: This game involves real money and can be addictive. Only individuals 18+ are eligible. Enjoy the thrill, but play at your own risk."</p>
                </div>
                <div className='text-center' style={{ height: '4vh' }}>
                  <p style={{ color: '#afafaf', fontSize: '12px' }}>Copyright 2023 jiorummylive.com All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
