import React from 'react'
import { Link } from 'react-router-dom'

export const Pricing = () => {
  return (
   <>
     <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh',fontWeight:'bold'}}>
      <div><h1>Pricing</h1></div>
    </div>
    <ol>
        <li>Tap on the Pay button</li>
        <li>Choose your Chip Pack. The ratio is 1:1, which means you pay ₹ 1, your balance is credited 1 chip.</li>
        <li>Complete your payment using any of the payment options. Your balance will update automatically, Enjoy playing!</li>
    </ol>
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'6vh',fontSize:'19px'}}>
  2023-2024  <Link to='https://www.maxwayinfotech.com/'> Maxway Infotech</Link>
  </div>
 
   </>
  )
}
