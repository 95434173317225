
import { AllRoutes } from './AllRoutes/AllRoutes';
import './App.css';
import { Home } from './components/Home';

function App() {
  return (
   <>
    <AllRoutes/>
   </>
  );
}

export default App;
