import React from 'react'

export const Privacy = () => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh', fontWeight: 'bold' }}>
        <h1>Privacy Policy</h1>
      </div>
      <div>

        <ul>
          <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>Privacy Policy:</h3>
            <p style={{ fontSize: '20px' }}>The Privacy Policy is designed to explain how Jio Rummy Live（"we", "Jio Rummy Live"） deal with User’s personal information when the User use any Jio Rummy Live’s game or service. We are committed to protecting User’s privacy. We will never sell or otherwise pass on User’s privacy information to a third party without User’s consent besides when it is required to provide User the service User is using. Jio Rummy Live is required to use the personal data of all the Users in accordance with the applicable laws of the land where the games are being played by the Users.
              <br></br>
              <br></br>
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>What We Collect:</span>
              <br></br>
              When the User uses any Jio Rummy Live’s game or service, the User is authorizing Jio Rummy Live to access certain information of the User. The information we collect is to provide and improve our services and to fulfill User’s requirement. In each case, we will collect, store, and use the information Users have provided according to this Privacy Policy. This includes User’s name, profile picture, gender, networks, user IDs, list of friends, date of birth, email address, and any other information User has set to public on User’s Facebook.
              <br></br>
              <br></br>
              When User uses any Jio Rummy Live’s game or service through a social network account such as Facebook, Apple, etc. User is authorizing us to access certain information from User’s profile for that site according to the privacy settings established at the SNS by the User and User’s friends such as User’s name, address, phone number, etc. which the SNS could provide to us through the SNS Application Programming Interface ("API").
              <br></br>
              <br></br>
              When the User uses any Jio Rummy Live’s game or service directly we may collect and store User information such as registration data, email address, and any other information that the User has provided on User’s profile.
              <br></br>
              <br></br>
              When the User accesses any Jio Rummy Live’s game on a Mobile Device we may collect User device and carrier information such as MAC Address, IP Address, geo-location, telephone number, the account information Users have associated with their device, country or other information the Users provide.
              <br></br>
              <br></br>
              If the User purchases a license to use in-game virtual items directly from any Jio Rummy Live’s game, we will collect the billing and financial information necessary to process User’s charges, which may include User’s postal and e-mail addresses. Jio Rummy Live may also receive the billing and payment information that the User provides when User purchase order is processed by another party, such as Facebook (for Facebook Credits) or Apple (for purchases on iOS devices). Our terms of service explain our policies and terms relevant to our charges and billing practices. Please note that purchases of third party credits or currencies like Facebook Credits may also be subject to additional policies. We do not store the credit card information.
              <br></br>
              <br></br>
              When the User uses any Jio Rummy Live’s game or service, we collect certain technical information in order to<br>

              </br>(i) analyze the usage of our sites and services;<br></br> (ii) provide a more personalized experience; and <br></br>(iii) manage advertising. We and service providers acting on our behalf, use Log Files and tracking technologies to collect and analyze certain types of technical information, including cookies, IP addresses, device type, device identifiers, browser types, browser language, referring and exit pages, and URLs, platform type, the number of clicks, domain names, landing pages, pages viewed and the order of those pages, the amount of time spent on particular pages, game state and the date and time of activity on our websites or games, and other similar information. In some cases, we will associate this information with User’s ID number for our internal use.
              <br></br>
              <br></br>
              We collect the following categories of your personal information and may sell it to third parties: identifiers, commercial information, internet or other electronic network activity information, geolocation data, and inferences drawn from these categories.
            </p>
          </li>
          <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>HOW WE USE THE INFORMATION WE COLLECT?</h3>
            <p>
              Only Jio Rummy Live has the right to the information. We will never sell or otherwise pass on User’s privacy information to a third party without User’s consent. We may use the information to notify User of important announcements regarding our software developments, software upgrades, special offers, to solicit User’s feedback and input and to provide User the application/software support. If User do not want to receive any announcement from us, User may manage notifications from User app. Users can also ask to be excluded from such announcements by sending us email to this effect. In addition, we may use User Information in a form that is not personally identifiable to help us in analyzing various aspects of user behavior for the purpose of improving our services.
              <br></br>
              <br></br>
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Controlling User Personal Information.</span>
              <br></br>
              User can terminate User’s account at any time which will remove User’s profile and other personal information from view. However, some information may be necessary for providing User our services and support. In that event we may not be able to provide User one or more of our services that User is using.
              <br></br>

              In Jio Rummy Live social games, we do not delete the past game results or records even if User deletes applications from User phone or do not join again. Jio Rummy Live will take reasonable steps to protect User’s personal information from loss, misuse, unauthorized access, disclosure or unauthorized alteration. By downloading any Jio Rummy Live software or creating an account or accessing or using the service User accepts and agrees to be bound by these terms of service.
            </p>
          </li>
          <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>Exceptions to Restriction in Disclosure of Information or Account Information</h3>
            <p style={{ fontSize: '20px' }}>
            Users understand and agree that we may be required to disclose any Information or Account Information by a competent legal authority or that we may voluntarily choose to disclose any such Information or Account Information under reasonable belief that this is necessary for any lawful purpose. We will be under no legal obligation to either inform User about the presence of such request or contest such a request for Information or Account Information whatsoever.
            </p>
            <br></br>
            <p style={{ fontSize: '20px' }}>The User understands and accepts that downloading the game of Jio Rummy Live is the action of the User on his/her own consent, and they shall assume responsibility for system damage, data lost and any other risks. Jio Rummy Live and its affiliates assume no responsibility for any direct, indirect, incidental, special and consequential damages and risks arising from using or failing to use Jio Rummy Live’ s game under any circumstances.</p>
          </li>
          <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>GRANT OF A LICENSE TO USE THE SERVICE</h3>
            <p style={{ fontSize: '20px' }}>
            Subject to User’s compliance with terms of service, Jio Rummy Live grants Users a revocable, non-exclusive limited license to access and use the services. This license is non-transferable. User agree to use the service for User own non-commercial purposes. Users play some Jio Rummy Live social games to win chips. Users do not play with real money. The chips User lose or win have no value in real money. At times User may “earn” or “buy or purchase” virtual in-game items. These virtual items do not refer to any credit balance of real currency or its equivalent. Jio Rummy Live reserves the right to terminate the license of any user without giving any reason. If Jio Rummy Live believes that a user has acquired chips from unauthorized sources and from sources that violate the terms of the service, it has the right to take away all or a part of the total chips with the user.
            </p>
            <br></br>
            <p style={{ fontSize: '20px' }}>
            The Users agree and acknowledge that the right, title and interest over the intellectual property rights over the game and related software shall be sole owned by Jio Rummy Live. If the User is involved with any infringement act, Jio Rummy Live has the right to terminate the services of the user and take appropriate actions under the applicable laws for infringement of such intellectual property rights of Jio Rummy Live.
            </p>
            <br></br>
            <p style={{ fontSize: '20px'}}>
            Downloading the game by Jio Rummy Live is free. Jio Rummy Live gives users free chips to begin with and regularly. This gives User limited license to play our games. If Users do not lose all User free chips, Users can play games for an unlimited period. If User lose User free chips, User can not play any more. However, users have the option to buy chips and join the game. Remember that the chips User buys and wins have no value in the real currency. These chips are not transferable and we do not offer refunds.
            </p>
            <br></br>
            <p style={{ fontSize: '20px' }}>
            By accepting to use the application, User fully understands and agrees that User can lose chips because of many reasons besides losing the game. Some of the reasons are client’s data connection, Internet connection, improper behavior of the client , Jio Rummy Live server problem, sudden increase in traffic, someone else using a User account or User account is hacked and many more.
            </p>
            <br></br>
            <p style={{ fontSize: '20px' }}>
            Jio Rummy Live is not liable for any loss of chips due to functioning as well as malfunctioning of its servers and software. Winning or not chiefly depends upon the random cards User gets. When User downloads a game issued by Jio Rummy Live, Users accept and agree to play it only for fun and entertainment and Jio Rummy Live is not liable for any damage or loss or whatsoever.
             </p>
          </li>
          <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>RULES AND RESTRICTIONS</h3>
            <p style={{ fontSize: '20px' }}>
            Failure to follow restrictions or terms of service can get a User account suspended, hacked or permanently banned. Please read the following restrictions which applies to use of the service:
            <br></br>
            <br></br>
            We recommend that minors over the age of 13 and below the age of 18 shall ask their parents for permission before sending any information about themselves to anyone over the Internet. Users must not use the service if users are under the age of 13. Users must deny anyone under 13 to use a User account. Users are fully responsible for any unauthorized use of the service including not limited to the use of credit card or any payment by any method.
            <br></br>
            <br></br>
            Users shall use User accounts only for non-commercial entertainment purposes. Users shall not use the service for any other purpose, such as collecting chips from various accounts or by any other means.
            <br></br>
            <br></br>
            Users shall not use User accounts for any illegal activity.
            <br></br>
            <br></br>
            Users shall not use User accounts to transmit repetitive messages (spam), junk e-mail, advertise and solicit.
            <br></br>
            <br></br>
            Users shall not use User accounts to transmit repetitive messages (spam), junk e-mail, advertise and solicit.Users shall not use User accounts to transmit repetitive messages (spam), junk e-mail, advertise and solicit.
            <br></br>
            <br></br>
            Users shall not use a User account to cheat or hack the game by any means. Losing on purpose as a means of transferring chips can be tracked easily, and this behavior will have User chips taken and banned.
            <br></br>
            <br></br>
            User shall not sublicense, lease, trade, gift, sell or otherwise transfer User account or associated virtual items partly or fully to anyone without written permission from Jio Rummy Live.
            <br></br>
            Users shall not buy or get chips, bonus and any other virtual items from any unauthorized source such as links from unknown sources or people promising free chips, etc.
            <br></br>
            Users are allowed one account per social network to use the service. In addition, Users cannot use multiple accounts to try to accumulate free chips. This behavior is easily detectable and will result in all User accounts being suspended and/or banned.
            <br></br>
            <br></br>
            Users shall not use scripts and manual processes to abusively accumulate free chips.
            <br></br>
            <br></br>
            Users shall not use any of the services or create an account or use an account if Users have been removed or banned from using the service.
            <br></br>
            <br></br>
            Users shall not use any of the Jio Rummy Live game or service if Users do not agree with the terms of service and User license to use the service shall immediately terminate.
            </p>
          </li>
          <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>The User has no right to carry out actions including but not limited to:</h3>
            <p style={{ fontSize:'20px'}}>
            Deleting all the information and contents related the copyright on Jio Rummy Live software and other copies;
            <br></br>
            Performing reverse engineering, reverse assembly, reverse compilation, etc., to the game of the Jio Rummy Live, regardless of any purpose of plagiarizing or changing the game software of Jio Rummy Live;
            <br></br>
            For information related to the software of game of the Jio Rummy Live (without the written consent of Jio Rummy Live) the user cannot (without authorization including but not limited to) use, copy, modify, link, reprint, assemble, release, publish, set up a mirror site, or use Jio Rummy Live game for these purposes without authorization to develop derivative products, works or services related to the software.
            <br></br>
            The User is not allowed to host, post, display, upload, modify, copy, transmit, publish, update, share or store the following information using Jio Rummy Live’ s game:
            <br></br>
            Information in violation of any law and regulation, department regulation or local applicable law;
            <br></br>
            Endangering national security, disclosing state secrets, subverting state power and undermining state unification;
            <br></br>
            Damaging national honor and interests;
            <br></br>
            Instigating ethnic hatred, ethnic discrimination and undermining ethnic unity;
            <br></br>
            Destroying religious policies of the State, promoting malevolent cults and arcane superstitions;
            <br></br>
            Insulting or slandering others and infringing on the lawful rights and interests of others;
            <br></br>
            Infringing on the intellectual property rights, trade secrets or other legal rights of others;
            <br></br>
            Information that harms the minor in any manner;
            <br></br>
            Information that deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
            <br></br>
            Information that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;
            <br></br>
            Information that threatens the unity, integrity, defense, security or sovereignty of counties where the games are played by the User, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or is insulting any other nation.
            <br></br>
            Jio Rummy Live reserves the right to terminate the license of any user.
            </p>
         </li>
         <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>Governing Law and Jurisdiction:</h3>
            <p style={{ fontSize:'20px'}}>
            If any provision set forth in this Privacy Policy is held invalid, in part or in whole, it shall not prejudice the validity of the remaining provisions of this Privacy Policy.
            <br></br>
            The validity, performance, interpretation and dispute settlement of this Privacy Policy and any non-contractual obligations arising out of or in connection with it shall be governed by the laws of countries where the games are being played by the Users;
            <br></br>
            Any dispute arising out of the content or enforcement of this Privacy Policy shall first be settled by the concerned parties through friendly consultation. If the aforesaid dispute cannot be resolved by the parties within 30 (thirty) days of such dispute arising, then the dispute shall be resolved by way of arbitration pursuant to the provisions of the applicable laws of the host country where the games are being played.
            <br></br>
            The seat and venue of the arbitration shall be at the place where the registered office of Jio Rummy Live is situated. Subject to aforesaid arbitration provisions, either party may initiate a lawsuit over such dispute in the relevant Court with jurisdiction at the place where the registered office of Jio Rummy Live is situated.
            </p>
          </li>
          <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>Governing Agreement</h3>
            <p style={{ fontSize:'20px'}}>
            The terms of this service govern the relationship between the User and Jio Rummy Live (“We”) regarding User’s use of any Jio Rummy Live’s game or service, (“Privacy Policy”). Please read this Privacy Policy carefully, and if the User has any question, feel free to contact Jio Rummy Live. Jio Rummy Live reserves the right to edit these terms of service and policies at any time. We hope that the User can peruse the changes periodically. The User is required to review and accept or don’t accept this Privacy Policy (minors i.e. User below the age of 18 years, shall review and perform this Privacy Policy accompanied by a legal guardian, if the minor exercises and performs the rights and obligations under this Privacy Policy, it shall be deemed that the minor has obtained the approval of the legal guardian). Unless the User accepts the terms of this Privacy Policy, the User is not entitled to use the relevant products and services under this Privacy Policy. The User’s registration and use shall be deemed that the User has accepted this Privacy Policy and agreed to be bound by the terms of this Privacy Policy.
            </p>
          </li>
          <li>
            <h3 style={{ fontWeight: 'bold', fontSize: '20px' }}>Suggestions or comments</h3>
            <p style={{ fontSize:'20px'}}>
            All Suggestions or comments relating to personal data must be sent to rummyjio@gmail.com       </p>
          </li>
        </ul>
      </div>
    </>
  )
}
