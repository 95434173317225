import React from 'react'
import { Link } from 'react-router-dom'

export const Cancellation = () => {
  return (
 <>
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'15vh',fontWeight:'bold'}}>
      <div><h1>Cancellation & Refund Policy</h1></div>
      <div><h6>Keep Your Account Active and Avoid Being Banned</h6></div>
    </div>
     <ul>
        <li>
            <h6>RULES AND RESTRICTIONS:</h6>
            <p>Jio Rummy Live has made commercially reasonable efforts to ensure that the users of Jio Rummy Live play the games in a manner which is fair to all players. However, please note that human behavior is complicated and detection of cheating is an approximation based on a number of variables. User discretion is therefore, advised strongly. In the event you feel that a particular user (or a group of users) is or are cheating in a game, please reach out to us on the following email ids:
            <br></br>
            Please note that failure to follow Jio Rummy Live rules or Terms of Service, irrespective of intention or motive, can get your account suspended, hacked or permanently banned. Please read the following list of PROHIBITED actions.</p>                    
        </li>

        <li>
            <h6>DO NOT:</h6>
            <p>Use third party software, scripts, or bots, to access or play Jio Rummy Live in any way. (See below)
            <br></br>
            Attempt to get free chips by clicking links from unknown sources or people promising free chips - this may get your account phished or hacked. We will not be responsible if either of these happen to your account.
            <br></br>
            Use profanity or language that discriminates or maliciously targets another individual in any way. This includes your profile picture.
            <br></br>
            Create more than one account to play Jio Rummy Live from or store chips - all accounts will be banned! (See below)
            <br></br>
            Lose on purpose as a means of transferring chips - we have the means to track this and you will forfeit all your chips, and may potentially be banned by us. (See below)
            <br></br>
            Attempt to sell chips using any means or under any circumstances.
            <br></br>
            Attempt to buy chips from anyone other than Jio Rummy Live, Jio Rummy Live or its authorized resellers in appropriate markets.
            <br></br>
            Playing with multiple accounts or friends on the same table to spoil other players' games is not allowed. We have the means to track these activities and if we discover or have reasonable suspicion that you are colluding with others, we may confiscate all your chips and/ or ban you from playing Jio Rummy Live.
            <br></br>
            Do not Share your Facebook password with anyone. Jio Rummy Live its representative does not request for your Facebook password under any circumstances.
            </p>
        </li>
        <li>
            <h6>CHIP TRANSFERS:</h6>
            <p>
            If suspected, the below-mentioned activity will result in the violation of our Terms of Service and will be aggressively enforced through Jail.
            <br></br>
            Losing hands-on purpose at the table to transfer chips to anyone else (or yourself on another account)
            <br></br>
            Attempt to sell chips using any means or under any circumstances.
            <br></br>
            Attempt to buy chips from anyone other than Jio Rummy Live, Jio Rummy Live, or its authorized resellers in appropriate markets..
            </p>
        </li>
        <li>
            <h6>COLLUSION:</h6>
            <p>Playing with multiple accounts or friends on the same table to spoil other players' games is not allowed. We have the means to track these activities and if we discover or have reasonable suspicion that you are colluding with others, we may confiscate all your chips and/ or ban you from playing Jio Rummy Live</p>
        </li>
        <li>
            <h6>MULTIPLE ACCOUNTS:</h6>
            <p>You are allowed one account only per social network. In addition, you cannot use multiple accounts to try to accumulate free chips (aka chip farming). Should we detect any such behavior, we will take suitable penalty measures, including confiscating your chips, suspending your accounts and/ or banning you permanently. The discretion of the penalty and mode of enforcement lies entirely upon us.</p>
        </li>
        <li>
            <h6>CHIP FARMING / SCRIPTING:</h6>
            <p>Using scripts and manual processes to accumulate free chips is an abuse of the terms and conditions of Jio Rummy Live. These behaviors are absolutely not allowed and may result in all your chips being confiscated, your account(s) being suspended or you being permanently banned.</p>
        </li>
        <li>
            <h6>REFUND AND CANCELLATION POLICY:</h6>
            <p>Once chips have been granted, we do not offer refunds. If your chips have not been granted for whatever reason, please contact us and your chips will be granted immediately. In case we are unable to grant you chips, your refund will be processed from our end within 7 working days.</p>
        </li>

      </ul>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'6vh',fontSize:'19px'}}>
  2023-2024  <Link to='https://www.maxwayinfotech.com/'> Maxway Infotech</Link>
  </div>
 
 </>
  )
}
