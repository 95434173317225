import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Home } from '../components/Home'
import { Cancellation } from '../components/ExtraPages/Cancellation'
import { Aboutus } from '../components/ExtraPages/About'
import { Terms } from '../components/ExtraPages/Terms'
import { Privacy } from '../components/ExtraPages/Privacy'
import { Contact } from '../components/ExtraPages/contact'
import { Pricing } from '../components/ExtraPages/Pricing'



export const AllRoutes = () => {
  return (
    <Routes>
        <Route exact path='/' element={<Home/>}></Route>
        <Route exact path='/cancelation' element={<Cancellation/>}></Route>
        <Route exact path='/about' element={<Aboutus/>}></Route>
        <Route exact path='/terms' element={<Terms/>}></Route>
        <Route exact path='/privacy' element={<Privacy/>}></Route>
        <Route exact path='/contact' element={<Contact/>}></Route>
        <Route exact path='/pricing' element={<Pricing/>}></Route> 
    </Routes>
  )
}
